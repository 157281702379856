<script lang="ts">
  import { buttonSizeClass, type ButtonSize } from '$lib/button-styles'
  import Spinner from '$lib/components/Spinner.svelte'
  import { track } from 'src/utils/track'
  import { createEventDispatcher } from 'svelte'

  export let label = null
  export let size: ButtonSize = 'md'
  export let loading = false
  let className = ''
  export { className as class }
  export let as = 'button'

  let sizeClass = buttonSizeClass(size)

  /**
   * @param {string} eventName
   * Event Name to send to tracking service. Use the "Object Action" (Noun<>Verb) naming convention for all Track events, for example, 'Article Bookmarked'.
   */
  export let eventName: string = undefined
  export let eventDetails: Record<string, string> = undefined

  const dispatch = createEventDispatcher()
  function handleClickWithTracking(event: MouseEvent) {
    if (eventName) {
      track(eventName, eventDetails)
    }
    dispatch('click', event.detail)
  }

  function preload() {
    dispatch('preload')
  }
</script>

<svelte:element
  this={as}
  class={`${sizeClass}
        flex
        flex-row
        items-center
        justify-center
        appearance-none
        text-base
        tracking-wide
        uppercase
        transition-all
        duration-75
        font-semibold
        hover:opacity-90
        active:from-rose-600
        active:to-amber-600
        focus:from-rose-600
        focus:to-amber-600
        bg-gradient-to-tr
        to-brand-mid-gradient
        text-white
        text-center
        rounded-full
        whitespace-nowrap
        leading-4
        focus:outline-none
        active:text-white
        disabled:bg-grey-md
        disabled:text-black
        disabled:from-transparent
        disabled:to-transparent
        disabled:cursor-not-allowed
        from-brand-30-to-90 ${className}`}
  {...$$restProps}
  on:click|preventDefault|stopPropagation|trusted={handleClickWithTracking}
  on:mouseenter={preload}
  on:touchstart={preload}
>
  {#if loading}
    <span class="pr-2 -my-1">
      <Spinner {size} embedded />
    </span>
  {/if}
  <span><slot>{label}</slot></span>
</svelte:element>
